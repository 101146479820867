import Image from "components/image";

const LargeWithImage = ({ fields }) => (
  <div className="bg-white lg:py-24">
    <div className="pb-16 bg-brand-600 lg:pb-0 lg:z-10 lg:relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
        {fields.image && (
          <div className="relative lg:-my-8">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
            ></div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
              <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                <Image
                  fields={fields.image}
                  className="object-cover lg:h-full lg:w-full rounded-xl shadow-xl overflow-hidden"
                  fill
                  alt={fields.name}
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
            <blockquote>
              <div>
                <svg
                  className="h-12 w-12 text-white opacity-25"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="mt-6 text-2xl font-medium text-white">
                  {fields.text}
                </p>
              </div>
              {fields.name && (
                <footer className="mt-6">
                  <p className="text-base font-sans font-medium text-white">
                    {fields.name}
                  </p>
                </footer>
              )}
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Block = ({ fields }) => (
  <section className="mt-16 lg:mt-20">
    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="relative lg:flex lg:items-center">
        <div className="relative lg:ml-10">
          <svg
            className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-brand-200 opacity-50"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 144 144"
            aria-hidden="true"
          >
            <path
              strokeWidth="2"
              d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
            />
          </svg>
          <blockquote className="relative">
            <div className="text-2xl leading-9 font-medium text-gray-900">
              <p>
                {fields.text}
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex">
                <div className="">
                  <div className="text-base font-medium text-gray-900">
                  {fields.name}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
);

export default function Testimonial({ fields, sys }) {
  if (fields.type === "Large With Image") {
    return <LargeWithImage fields={fields} />;
  }

  if (fields.type === "Block") {
    return <Block fields={fields} />;
  }

  return (
    <section className="my-12 overflow-hidden md:my-20">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative prose mx-auto">
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-sans font-medium text-gray-900">
              <p>{fields.text}</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                {fields.image && (
                  <div className="md:shrink-0">
                    <div className="mx-auto w-10">
                      <Image
                        fields={fields.image}
                        width={50}
                        height={50}
                        alt={fields.name}
                        className="mx-auto h-10 w-10 rounded-full"
                      />
                    </div>
                  </div>
                )}
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {fields.name}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}
